import { Box, useMediaQuery } from '@mui/material';

// project import
import Profile from './Profile';
import MobileSection from './MobileSection';
import { useLocation } from 'react-router-dom';
import SearchMultiTypes from 'components/SearchMultiTypes';

// ==============================|| HEADER - CONTENT ||============================== //

const HeaderContent = () => {
  const matchesXs = useMediaQuery((theme) => theme.breakpoints.down('md'));
  const location = useLocation();
  return (
    <>
      <Box sx={{ width: '100%', ml: 1 }} />
      {/* {location.pathname !== '/' && <SearchMultiTypes />}
      {location.pathname === '/' && <Box sx={{ width: '100%', ml: 1 }} />} */}
      {/* <Notification /> */}
      {!matchesXs && <Profile />}
      {matchesXs && <MobileSection />}
    </>
  );
};

export default HeaderContent;
