// assets
import { DashboardOutlined, CarryOutOutlined, CarOutlined, SettingOutlined, CodeSandboxOutlined } from '@ant-design/icons';

// ==============================|| MENU ITEMS - DASHBOARD ||============================== //

const dashboard = {
  id: 'group-dashboard',
  title: 'Navigation',
  type: 'group',
  children: [
    {
      id: 'workOrders',
      title: 'Work Orders',
      type: 'item',
      url: '/',
      icon: CodeSandboxOutlined,
      breadcrumbs: false
    },
    {
      id: 'customer',
      title: 'Customers',
      type: 'item',
      url: '/customer',
      icon: CodeSandboxOutlined,
      breadcrumbs: false
    },
    {
      id: 'equipment',
      title: 'Equipments',
      type: 'item',
      url: '/equipment',
      icon: CodeSandboxOutlined,
      breadcrumbs: false
    },

    {
      id: 'configuration',
      title: 'Configuration',
      type: 'item',
      url: '/configuration',
      icon: SettingOutlined,
      breadcrumbs: false
    }
  ]
};

export default dashboard;
