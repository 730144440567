// ==============================|| THEME CONFIG  ||============================== //

const config = {
  defaultPath: '/',
  fontFamily: "'Public Sans', sans-serif",
  i18n: 'en',
  miniDrawer: false,
  container: true,
  mode: 'light',
  presetColor: 'default',
  themeDirection: 'ltr'
};

export default config;
export const drawerWidth = 260;

export const twitterColor = '#1DA1F2';
export const facebookColor = '#3b5998';
export const linkedInColor = '#0e76a8';

export const domain = process.env.NODE_ENV === 'production' ? location.origin : 'https://localhost:5001';
// Your web app's Firebase configuration

export const firebaseConfig = {
  apiKey: "AIzaSyAfwZcGQSPvXyNjXhLDqsBQR_1lRfnM1J4",
  authDomain: "workman-50c09.firebaseapp.com",
  projectId: "workman-50c09",
  storageBucket: "workman-50c09.appspot.com",
  messagingSenderId: "877919248148",
  appId: "1:877919248148:web:0bf791c411c316e15a2c01"
};
