import { lazy } from 'react';

// project import
import Loadable from 'components/Loadable';
import MainLayout from 'layout/MainLayout';
import { Navigate } from 'react-router-dom';

import ProtectedRoute from './ProtectedRoute';
import React from 'react';
// render - dashboard
const Report = Loadable(lazy(() => import('pages/Report')));

const Configuration = Loadable(lazy(() => import('pages/Configuration')));
const Customer = Loadable(lazy(() => import('pages/Customer')));
const Equipments = Loadable(lazy(() => import('pages/Equipments')));
const Equipment = Loadable(lazy(() => import('pages/Equipment')));

const WorkOrders = Loadable(lazy(() => import('pages/WorkOrders')));
const WorkOrder = Loadable(lazy(() => import('pages/WorkOrder')));
const Service = Loadable(lazy(() => import('pages/Service')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
  path: '/',
  element: (
    <ProtectedRoute>
      <MainLayout />
    </ProtectedRoute>
  ),
  children: [
    {
      path: '/',
      element: <WorkOrders />
    },
    {
      path: 'workOrder/:id',
      element: <WorkOrder />
    },
    {
      path: 'service/:id',
      element: <Service />
    },
    {
      path: 'report/:id',
      element: <Report />
    },
    {
      path: 'customer',
      element: <Customer />
    },
    {
      path: 'equipment',
      element: <Equipments />
    },
    {
      path: 'equipment/:id',
      element: <Equipment />
    },
    {
      path: 'configuration',
      element: <Configuration />
    }
  ]
};

export default MainRoutes;
